h3.ihFooterColumnHeader {
    margin-top: 0;
    margin-bottom: 0;
}

.ihFooterColumnGroup {
    overflow: hidden;
}

.ihFooterColumnGroup {
    list-style: none;
    color: #fff;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
}

.ihFooterColumnGroup li {
    display: inline-block;
    float: left;
    width: 25%;
}

.ihFooterColumnHeader {
    color: #fff;
    font-size: 19px;
    font-weight: bold;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

}

.ihFooterColumnList {
    padding-left: 0;
    margin-left: 0;
}

.ihFooterColumnList li {
    width: 100%;

}

.ihFooterColumnList li a {
    color: #fff;
    font-size: 13px;

}